import {
  fetchSalesTargetsAPI,
  fetchSalesTargetAPI,
  createSalesTargetAPI,
  updateSalesTargetAPI,
  deactivateSalesTargetAPI,
} from '@/services/apis'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async fetchSalesTargets(ctx, queryParams) {
      try {
        return await this.$axios.get(fetchSalesTargetsAPI, { params: queryParams })
      } catch (error) {
        return error
      }
    },
    async fetchSalesTarget(ctx, { id }) {
      try {
        return await this.$axios.get(`${fetchSalesTargetAPI}/${id}`)
      } catch (error) {
        return error
      }
    },
    async createSalesTarget(ctx, params) {
      try {
        return await this.$axios.post(createSalesTargetAPI, params)
      } catch (error) {
        return error
      }
    },
    async updateSalesTarget(ctx, params) {
      try {
        return await this.$axios.post(updateSalesTargetAPI, params)
      } catch (error) {
        return error
      }
    },
    async deactivateSalesTarget(ctx, id) {
      try {
        return await this.$axios.post(`${deactivateSalesTargetAPI}/${id}`)
      } catch (error) {
        return error
      }
    },
  },
}
