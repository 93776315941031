<template>

  <div>

    <!-- Filters -->
    <sales-targets-list-filters
      :description-filter.sync="descriptionFilter"
      :account-type-filter.sync="accountTypeFilter"
      :status-filter.sync="statusFilter"
      :account-type-options="accountTypeOptions"
      :status-options="statusOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <!-- <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              /> -->
              <b-button
                variant="primary"
                :to="{ name: 'sales-targets-create' }"
              >
                <span class="text-nowrap">Add Sales Target</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refSalesTargetListTable"
        class="position-relative"
        :items="fetchSalesTargets"
        responsive
        :fields="tableColumns"
        primary-key="targetId"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Agency -->
        <template #cell(agency)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ resolveAccountType(data.item.agency) }}</span>
          </div>
        </template>

        <!-- Column: Start Date -->
        <template #cell(start_date)="data">
          <div class="text-nowrap">
            {{
              formatDate(data.item.start_date, {
                dateStyle: 'medium',
                timeStyle: 'medium'
              })
            }}
          </div>
        </template>

        <template #cell(end_date)="data">
          <div class="text-nowrap text-danger">
            {{
              formatDate(data.item.end_date, {
                dateStyle: 'medium',
                timeStyle: 'medium'
              })
            }}
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            v-if="data.item.status"
            pill
            :variant="`light-${resolveSalesTargetStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ resolveSalesTargetStatus(data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'sales-targets-edit', params: { id: data.item.targetId } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="initiateDeactivation(data.item.targetId)">
              <feather-icon icon="XOctagonIcon" />
              <span class="align-middle ml-50">Deactivate</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <confirm-modal
      :ref="'confirm-modal-component'"
      @confirm="deactivateSalesTarget"
    >
      <template v-slot:message>
        Are you sure you want to deactivate this Sales Target?
      </template>
    </confirm-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import { avatarText, formatDate } from '@core/utils/filter'
import store from '@/store'
import SalesTargetsListFilters from './SalesTargetsListFilters.vue'
import useSalesTargetsList from './useSalesTargetsList'
import {
  resolveSalesTargetStatus,
  resolveSalesTargetStatusVariant,
  resolveAccountType,

} from '@/utils/common-functions/resolvers'
import { statusOptions, accountTypeOptions } from '@/utils/computed-properties/system-codes/systemCodesComputedProperties'
import salesTargetStoreModule from '../salesTargetStoreModule'
import ConfirmModal from '@/components/modals/confirm-modal/ConfirmModal.vue'

export default {
  components: {
    SalesTargetsListFilters,
    ConfirmModal,

    BCard,
    BRow,
    BCol,
    // BFormInput,
    BButton,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  setup(_, { refs }) {
    const SALES_TARGET_APP_STORE_MODULE_NAME = 'app-sales-target'

    // Register module
    if (!store.hasModule(SALES_TARGET_APP_STORE_MODULE_NAME)) store.registerModule(SALES_TARGET_APP_STORE_MODULE_NAME, salesTargetStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SALES_TARGET_APP_STORE_MODULE_NAME)) store.unregisterModule(SALES_TARGET_APP_STORE_MODULE_NAME)
    })

    const {
      fetchSalesTargets,
      tableColumns,
      perPage,
      currentPage,
      totalRecords,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSalesTargetListTable,
      refetchData,

      initiateDeactivation,
      deactivateSalesTarget,

      // Extra Filters
      descriptionFilter,
      accountTypeFilter,
      statusFilter,
    } = useSalesTargetsList(refs)

    return {
      fetchSalesTargets,
      tableColumns,
      perPage,
      currentPage,
      totalRecords,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSalesTargetListTable,
      refetchData,

      initiateDeactivation,
      deactivateSalesTarget,

      // Filter
      avatarText,
      formatDate,

      // UI
      resolveAccountType,
      resolveSalesTargetStatus,
      resolveSalesTargetStatusVariant,

      accountTypeOptions,
      statusOptions,

      // Extra Filters
      descriptionFilter,
      accountTypeFilter,
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
