<template>
  <b-modal
    :ref="'confirm-modal'"
    title="Confirm"
    hide-header-close
    size="sm"
    ok-variant="danger"
    ok-title="Confirm"
    cancel-variant="light"
    button-size="sm"
    @ok="confirm"
  >
    <slot name="message" />
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'

export default {
  components: { BModal },
  setup(_, { emit }) {
    const confirm = () => {
      emit('confirm')
    }

    return {
      confirm,
    }
  },

}
</script>
