import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import useSystemCodes from '@/views/system-settings/system-codes/useSystemCodes'

export default function useSalesTargetsList(refs) {
  // Use toast
  const toast = useToast()

  const refSalesTargetListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'actions' },
    { key: 'description', sortable: true },
    { key: 'agency', sortable: true },
    { key: 'targetCount', sortable: true },
    { key: 'start_date', sortable: true },
    { key: 'end_date', sortable: true },
    { key: 'status', sortable: true },
  ]
  const perPage = ref(10)
  const totalRecords = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('targetId')
  const isSortDirDesc = ref(true)
  const descriptionFilter = ref(null)
  const accountTypeFilter = ref(null)
  const statusFilter = ref(null)
  const selectedSalesTarget = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refSalesTargetListTable.value ? refSalesTargetListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRecords.value,
    }
  })

  const refetchData = () => {
    refSalesTargetListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, descriptionFilter, accountTypeFilter, statusFilter], () => {
    refetchData()
  })

  const fetchSalesTargets = (ctx, callback) => {
    store
      .dispatch('app-sales-target/fetchSalesTargets', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        description: descriptionFilter.value,
        agency: accountTypeFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const records = response.data.data[0].targets

        callback(records)
        totalRecords.value = response.data.data[0].total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Sales Targets list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const initiateDeactivation = id => {
    refs['confirm-modal-component'].$refs['confirm-modal'].show()
    selectedSalesTarget.value = id
  }

  const deactivateSalesTarget = async () => {
    try {
      const response = await store.dispatch('app-sales-target/deactivateSalesTarget', selectedSalesTarget.value)
      if (response.data.status) {
        refetchData()
      }
    } catch (error) {
      console.error(error)
    }
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  // Call System Codes
  const {
    callAccountType,
    callSalesTargetStatus,
  } = useSystemCodes()
  callAccountType()
  callSalesTargetStatus()

  return {
    fetchSalesTargets,
    tableColumns,
    perPage,
    currentPage,
    totalRecords,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refSalesTargetListTable,

    refetchData,

    initiateDeactivation,
    deactivateSalesTarget,

    // Extra Filters
    descriptionFilter,
    accountTypeFilter,
    statusFilter,
  }
}
